import React, { useState, useEffect } from "preact/compat"
import { h, Fragment } from "preact"
import YDialog from "./YDialog";
import { useMediaQuery } from "usehooks-ts";

function convertRemToPixels(rem: number) {
	return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}


export default function Navbar(props: {mainPage?: boolean}) {
	const [show, setShow] = useState(true);
	const [absolute, setAbsolute] = useState(true);
	const [lastScrollY, setLastScrollY] = useState(0);

	const controlNavbar = () => {
		if (typeof window !== 'undefined') { 
			const absolute = convertRemToPixels(3.5) > window.scrollY
			const scrollingUp =  window.scrollY <= lastScrollY
			setShow(scrollingUp || absolute)
			setAbsolute(absolute && !scrollingUp)

			// remember current page location to use in the next move
			setLastScrollY(window.scrollY); 
		}
	};

	if (props.mainPage) {
		useEffect(() => {
			if (typeof window !== 'undefined') {
				window.addEventListener('scroll', controlNavbar);

				// cleanup function
				return () => {
					window.removeEventListener('scroll', controlNavbar);
				};
			}
		}, [lastScrollY]);
	}


	const biggerThanPad = useMediaQuery('(min-width: 1024px)')

	const [contactVisible, setContactVisible] = useState(false)

	return (
		<>
			<YDialog
				title="Contact Us."
				open={contactVisible}
				setOpen={setContactVisible}
				buttonsDef={[{type: "outline", text: "Done", action: () => {setContactVisible(false)}}]}
			>
				<div className='flex flex-col mt-4 overflow-x-auto mr-12'>
					<h3 className='text-lg'>General enquires:</h3>
					<a href="mailto:contact@youther.com.au" className='text-green-600 dark:text-green-400 underline'>contact@youther.com.au</a>
					<h3 className='text-lg mt-2'>Technical issues:</h3>
					<a href="mailto:reagan@youther.com.au" className='text-green-600 dark:text-green-400 underline'>reagan@youther.com.au</a>
				</div>
			</YDialog>
			<header className={`${props.mainPage ? 'fixed' : absolute} top-0 ${show ? '' : '-translate-y-[100%]' } w-full transition-[transform] h-14 px-2 bg-brandbg-50 dark:bg-brandbg-800 bg-opacity-40 dark:bg-opacity-40 z-40 backdrop-blur border-b border-brandbg-300 dark:border-brandbg-600 shadow-md`}>
				<nav className='h-full'>
					<ul className="space-x-4 flex style list-none items-center h-full w-full">
						<li className="mr-2 border-brandbg-500 h-full">
							{/*href="/"*/}
							<a className={"font-['KyivType_Sans_Heavy'] tracking-widest flex items-end mt-2"} onClick={() => {
								if (window.location.pathname != '/') {
									window.location.pathname = "/"
								}
								window.scrollTo({ top: 0, behavior: 'smooth' })
							}}>


								<svg viewBox="0 0 50 60" className='h-8'>
									<defs>
										<linearGradient id="linear-gradient" x1="22.25" y1="17.02" x2="36.12" y2="29.56" gradientUnits="userSpaceOnUse">
											<stop offset="0" stopColor="#4da167"/><stop offset="1" stopColor="#26703d"/>
										</linearGradient>
										<linearGradient id="linear-gradient-2" x1="41.83" y1="9.23" x2="22.6" y2="52.7" gradientUnits="userSpaceOnUse">
											<stop offset="0" stopColor="#91e09a"/><stop offset="1" stopColor="#3bc14a"/>
										</linearGradient>
									</defs>
									<g>
										<path fill='url(#linear-gradient)' d="M34.33,25.76c-9.56-8.66-10.07-15.04-10.07-15.04,0,0-5.09,9.07,6.07,21.72"/>
										<path fill='url(#linear-gradient-2)' d="M34.08,51.65s14.93-34.57,6.58-43.34c0,0-2.72,22.56-28.61,43.34h22.03Z"/>
									</g>
								</svg>


								OUTHER.
							</a>
						</li>
						{ biggerThanPad &&
							<>
								<li>
									<a href="/faq">
										FAQs
									</a>
								</li>
							</>
						}
						<li className='!ml-auto'>
							<a onClick={() => setContactVisible(true)}>Contact us</a>
						</li>
					</ul>
				</nav>
			</header>
		</>
	)
}

