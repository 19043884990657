import * as React from "preact/compat"
import { h } from "preact"

interface YButtonProps {
	className?: string
	type?: "outline" | "flat" | "filled"| "raised"
	colorname?: string
	disabled?: boolean
	onClick?: () => void
	href?: string,
	children: React.ReactNode
}

export default function YButton({className = "", type = "raised", colorname = "brand", disabled = false, onClick, href, children}: YButtonProps) {
	let classes = className + " transition"
	if (type == "flat" || type == "outline") {
		classes += `
			text-${colorname}-800
			hover:enabled:text-${colorname}-600
			dark:text-${colorname}-300
			dark:hover:enabled:text-${colorname}-200
			disabled:text-brandbg-400
			dark:disabled:text-brandbg-600
		`
		if (type == "outline") {
			classes += `
				rounded-full
				border border-2
				border-${colorname}-800
				hover:enabled:border-${colorname}-600
				disabled:border-gray-500
				dark:disabled:border-gray-600
				dark:border-${colorname}-300
				dark:hover:enabled:border-${colorname}-200
			`
		}
	} else {
		classes += `
			rounded-full
			text-brandbg-50
			dark:text-brandbg-800
			shadow-gray-700  hover:enabled:shadow-gray-700
			shadow-sm disabled:shadow-sm disabled:opacity-75
			hover:shadow-md hover:disabled:shadow-sm
			bg-${colorname}-300 dark:${colorname}-300
		`
	}
	if (href !== null && href !== undefined) {
		return <a type="button" className={classes + " " + className} onClick={onClick} href={href}>{children}</a>
	}
	return <button type="button" className={classes + " " + className} disabled={disabled} onClick={onClick}>{children}</button>
}

