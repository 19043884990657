import { Transition, Dialog } from "@headlessui/react"
import * as React from "preact/compat"
import { h } from "preact"
import YButton from "./YButton"

interface DialogButtonDefinition {
	text: string
	action: () => void
	type: "outline" | "flat" | "filled" | "raised"
}

export default function YDialog({title, open, setOpen, buttonsDef, children}: {title: string, open: boolean, setOpen: (arg0: boolean) => void, children: React.ReactNode, buttonsDef: DialogButtonDefinition[]}) {
	return (
	<Transition appear show={open} as={React.Fragment}>
		<Dialog className="fixed z-40 left-0 top-0 w-full h-full flex justify-center items-center px-4" open={open} onClose={() => setOpen(false)}>
			<div className="fixed z-40 inset-0 bg-black bg-opacity-50 backdrop-blur" />
			<Dialog.Panel className="z-[1000] relative bg-brandbg-50 dark:bg-brandbg-900 rounded-2xl p-4 max-w-full">
				<Dialog.Title className="text-3xl flex flex-row items-end">
					<span className="pb-1">{title}</span>
				</Dialog.Title>


				{children}

				<div className="flex flex-row justify-end mt-4">
					{
						buttonsDef.map((item, idx) => 
							<YButton type={item.type} key={idx} className="px-2 py-1" onClick={item.action}>Done</YButton>
						)
					}
				</div>
			</Dialog.Panel>
		</Dialog>
	</Transition>
	)
}
