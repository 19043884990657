import { Router } from 'preact-router';
import { Fragment, h } from 'preact';
import Navbar from './components/navbar';
import MainPage from './MainPage';
import FAQ from './Faq';


const App = () => (
	<Router>
		<MainPage path="/"/>
		<FAQ path="/faq"/>
	</Router>
);

export default App;
